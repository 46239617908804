import React, { Component } from 'react';

class InputGroup extends Component {
  render() {
	return (
		<div className="inputGroup" key={this.props.keyName}>
			<label className="label">{this.props.formLabel}</label>
			<input 
				type="text" 
				name={this.props.postLabel} 
				className="input" 
				defaultValue={this.props.formValue} 
			/>
		</div>
	);
  }
}

export default InputGroup;
