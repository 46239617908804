import React, { Component } from 'react';
import Logo from '../images/ts_logo.png';

class Header extends Component {
  render() {
    return (
    	<div className="headerContainer">
			<div className="tsHeader">
				<img className="header-img" src={Logo} alt="TouchSource logo"/> 
				<div>TouchSource, LLC</div>
				<div>1370 Miners Drive, Suite 103</div>
				<div>Lafayette, CO 80026</div>
				<br/>
				<div>Service & Support 866-476-1873</div>
			</div>
	    	<div className="tsClientInfo">{this.props.headerData}</div>
    	</div>
    );
  }
}

export default Header;
