import * as md5 from 'md5';
import kali from 'kali';
import React, {Component} from 'react';
import './App.css';
import Header from './components/Header.js';
import InputGroup from './components/InputGroup.js';
import InputSelectGroup from './components/InputSelectGroup.js';
import moment from 'moment-timezone'

const API_PREFIX = process.env.REACT_APP_API_PREFIX;

class App extends Component {

	state = {
		update:     false,
		sessionKey: `Awmog7OsJ3e86yadyI2fGQaxmWgtAQ7t8IlBnStcqEDO7swAoEgQGaG6VB3A=`,
		states:     [],
		apiPrefix:  API_PREFIX,
		data:       ``,
		authValue: '',
		disabled: false
	}

	componentDidMount = () => {
		console.log("----test----")
		let mod_id = this.getQueryParam(`mod_id`);
		let prop_num = this.getQueryParam(`prop_num`);
		mod_id = window.atob(mod_id);
		mod_id = parseInt(mod_id, 10);
		this.getStatesData();
		if (!mod_id) {
			console.error('================= URL param mod_id not found =================');
		} else if (!prop_num) {
			console.error('================= URL param prop_num not found =================');
		} else {
			this.getQuoteData(mod_id, `modQuoteEntry`, prop_num);
		}
	}

	md5GetQueryParams = () => {
		let ts = parseInt(new Date().getTime() / 1000, 10);
		let sKey = md5(`${this.state.sessionKey}_${ts}`);
		return `auth_id=${this.state.sessionKey}&auth_key=${sKey}&auth_ts=${ts}`;
	}

	md5PostXAuthHeaders = () => {
		let ts = parseInt(new Date().getTime() / 1000, 10);
		let sKey = md5(`${this.state.sessionKey}_${ts}`);
		let headers = {
			'X-Auth-ID':  this.state.sessionKey,
			'X-Auth-Key': sKey,
			'X-Auth-TS':  ts
		};
		return headers;
	}

	getQueryParam = (param) => {
		let query = window.location.search.substring(1);
		let vars = query.split('&');
		for (let i = 0; i < vars.length; i++) {
			let pair = vars[i].split('=');
			if (pair[0] === param) {
				return pair[1];
			}
		}
		return false;
	}

	getQuoteData = (id, type, prop_num) => {
		let qps = this.md5GetQueryParams();
		let dataURL = `//${this.state.apiPrefix}/bronco/legacy/${type}/${id}/?${qps}&prop_num=${prop_num}`;

		new kali().get(dataURL, {
			success: (_kali, res, contents) => {
				if (contents && contents.data) {
					console.log(contents.data);
					this.setState({
						data: contents.data
					});
				}
			},

			failure: (_kali, err) => {
				console.error('BOOM: Kali says you broke it!!!!');
				console.error(err);
				_kali.retry(5000);
				return this.setState({errorMsg: err});
			}

		});
	}

	getStatesData = () => {
		let qps = this.md5GetQueryParams();
		let dataURL = `//${this.state.apiPrefix}/bronco/legacy/customersStatesDropDown/?${qps}`;

		new kali().get(dataURL, {
			success: (_kali, res, contents) => {
				if (contents && contents.data) {
					console.log(contents.data);
					this.setState({
						states: contents.data
					});
				}
			},

			failure: (_kali, err) => {
				console.error('BOOM: Kali says you broke it (this time in States)!!!!');
				console.error(err);
				_kali.retry(5000);
				return this.setState({errorMsg: err});
			}

		});
	}

	printDate = (type) => {
		let d = new Date();
		let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
		const day = d.getDate();
		const year = d.getFullYear();
		const year2Digit = year.toString().slice(-2);
		if (type === 'today') {
			let returnDate = `${months[d.getMonth()]} ${day}, ${year}`;
			return returnDate;
		}
		let quoteDate = `${year2Digit}${d.getMonth() + 1}${day}`;
		return quoteDate;
	}

	headerData = (array) => {
		let quoteNum = this.printDate();
		let results = [<div className="headerNumber" key="headerNum">Modification Quote #{this.state.data.property_number}-{quoteNum}</div>];
		array.forEach((data, i) => {
			if (i === 0) { // add label
				results.push(
					<div className="headerSectionLabel" key="headerLabel_1">Prepared for:</div>
				);
			} else if (i === 4) { // add label
				results.push(
					<div className="headerSectionLabel" key="headerLabel_2">Prepared by:</div>
				);
			}
			for (let info in data) {
				if (info === 'value') {
					if (i === 0) { // add bold to first item
						results.push(
							<div className="headerData" key={`headerData_${i}`}>
								<span className="bold">{data[info]}</span>
							</div>
						);
					} else {
						results.push(
							<div className="headerData" key={`headerData_${i}`}>{data[info]}</div>
						);
					}
				}
			}
		});
		return results;
	}

	buildForm = (array) => {
		let results = [];
		array.forEach((data, i) => {
			let group = [];
			for (let info in data) {
				group.push(data[info]);
				console.log(info);
			}
			console.log(group);
			if (group[0] === 'State') {
				return results.push(
					<InputSelectGroup
						key={`${group[0]}_${i}`}
						formLabel={group[0]}
						formValue={this.state.data[group[1]]}
						postLabel={group[1]}
						valueArray={this.state.states}
					/>
				);
			}
			results.push(
				<InputGroup
					key={`${group[0]}_${i}`}
					formLabel={group[0]}
					formValue={this.state.data[group[1]]}
					postLabel={group[1]}
				/>
			);
		});
		return results;
	}

	buildHiddenForm = (array) => {
		let results = [];
		array.forEach((data, i) => {
			results.push(<input key={i} name={data.name} value={data.value} type="hidden" />);
		});
		return results;
	}

	validateEntry = () => {
		let validateText = document.getElementById('authName').value;
		let strippedText = validateText.replace(/^\s+/, '').replace(/\s+$/, '');
		if (strippedText.length > 1 && strippedText !== '') {
			document.querySelector('.errorMsg').classList.add('hidden');
			return true;
		}
		document.querySelector('.errorMsg').classList.remove('hidden');
		return false;

	}

	handleAuthValue = (e) => {
		e.preventDefault();
		this.setState({authValue: e.target.value})
	}

	submitSend = (e) => {
		e.preventDefault();
		if (this.validateEntry() === false) {
			return;
		}
		if (this.state.disabled) {
			return;
		}
		let formData = new FormData(document.getElementById('formContainer'));
		console.log('==============formData==============');
		let postObj = {};
		for (let [k, v] of formData.entries()) {
			console.log(`${k} = ${v}`);
			postObj[k] = v;
			if (k === 'ID') {
				postObj[k] = parseInt(v, 10);
			}
			if (k === 'DeletedAt') {
				postObj[k] = null;
			}
		}
		let authHeaders = this.md5PostXAuthHeaders();

		new kali({ body: postObj, headers: authHeaders }).post(`https://${this.state.apiPrefix}/bronco/legacy/customersModquotes/`, {
			success: (_kali, res, contents) => {
				const message1 = document.getElementById('msg1');
				message1.classList.add('slideUp1');
				const message2 = document.getElementById('msg2');
				message2.classList.add('slideUp2');
				this.setState({disabled: true, authValue: ""})
			},

			failure: (_kali, err) => {
				console.error(err);
				alert('Issue writing back data, please retry submission');
			}
		});

		let plainDate = moment();
		let fmtDate = plainDate.format('ddd MMM DD HH:mm:ss UTC YYYY');
		let data = this.state.data
		let dataObj = {}
		dataObj.id = 0
		dataObj.project = data.project_id
		dataObj.call_date = fmtDate
		dataObj.dsi_point_person = data.tsi_contact
		dataObj.description = data.product_1_desc
		dataObj.contact_name = data.contact_name
		dataObj.contact_phone = data.contact_phone
		dataObj.contact_email = data.contact_email
		dataObj.cost = data.product_1_price

		new kali({body: dataObj, headers: authHeaders}).post(`https://${this.state.apiPrefix}/bronco/legacy/customersModifications/`, {
			success: (_kali, res, contents) => {
				console.log("success")
			},

			failure: (_kali, err) => {
				console.error(err);
				alert('Issue writing back data, please retry submission');
			}
		});
	}

	dollarString = (num) => {
		let number = parseFloat(num).toFixed(2);
		if (isNaN(parseFloat(num))) {
			console.log(`${number} is Nan Nan Nan Nan Nan Nan`);
			number = 0;
		}

		return number;
	}

	totalAmount = (customerItemData) => {
		let total = parseFloat(customerItemData[2]);
		console.log('TOTAL');
		console.log(total);
		if (customerItemData[5]) {
			total += parseFloat(customerItemData[5]);
			console.log(total);
		}
		if (customerItemData[8]) {
			total += parseFloat(customerItemData[8]);
			console.log(total);
		}

		let decimalsString = total.toString();
		if (!decimalsString.split('.')[1]) {
			decimalsString += '.00';
		}
		return this.dollarString(total);
	}

	renderListItems = (customerItemData) => {
		let items = [];
		if (!customerItemData[0] === false) {
			items.push(
				<div className="itemRow" key="rowItem1">
					<div className="item w-20">{customerItemData[0]}</div>
					<div className="item w-65">{customerItemData[1]}</div>
					<div className="item ta-c w-15">{this.dollarString(customerItemData[2])}</div>
				</div>
			);
		}
		if (!customerItemData[3] === false) {
			items.push(
				<div className="itemRow" key="rowItem2">
					<div className="item w-20">{customerItemData[3]}</div>
					<div className="item w-65">{customerItemData[4]}</div>
					<div className="item ta-c w-15">{this.dollarString(customerItemData[5])}</div>
				</div>
			);
		}
		if (!customerItemData[6] === false) {
			items.push(
				<div className="itemRow" key="rowItem3">
					<div className="item w-20">{customerItemData[6]}</div>
					<div className="item w-65">{customerItemData[7]}</div>
					<div className="item ta-c w-15">{this.dollarString(customerItemData[8])}</div>
				</div>
			);
		}
		console.log(customerItemData);
		return (
			<React.Fragment>
				<div className="itemsListHeader">
					<div className="w-20">ITEM</div>
					<div className="w-65">DESCRIPTION</div>
					<div className="w-15 ta-c">PRICE</div>
				</div>
				{items}
				<div className="finalRow">
					<div className="paymentMsg fg-1">
						<div className="warn">PLEASE NOTE:</div>
						<div>Depending on your location, sales tax will be added to your invoice.</div>
					</div>
					<div className="total w-15 ta-c" id="totalDue">{this.totalAmount(customerItemData)}</div>
				</div>
			</React.Fragment>
		);
	};

	render() {
		if (!this.state.data) {
			return <div className="App">Waiting for Data...</div>;
		}
		console.log('this.state.data');
		console.log(this.state.data);

		const auth = this.state.authValue
		const isEnabled = auth

		let customerItemData = ['1', '2', '3'];

		let po_number = this.state.data.po_number;
		let authorization_name = this.state.data.authorization_name;

		let customerHeaderData = [
			{label: 'Company', value: this.state.data.property_name},
			{label: 'Name', value: this.state.data.contact_name},
			{label: 'Phone', value: this.state.data.contact_phone},
			{label: 'Email', value: this.state.data.contact_email},
			{label: 'TS_Contact', value: this.state.data.tsi_contact},
			{label: 'Date', value: this.printDate('today')}
		];

		let customerBillData1 = [
			{label: 'Company', value: 'invoice_company'},
			{label: 'Contact', value: 'invoice_contact'},
			{label: 'Address', value: 'invoice_address'},
			{label: 'Suite', value: 'invoice_address_2'},
			{label: 'City', value: 'invoice_city'}
		];

		let customerBillData2 = [
			{label: 'State', value: 'invoice_state_prov'},
			{label: 'Zip', value: 'invoice_zip_postal'},
			{label: 'A/P Contact', value: 'ap_contact'},
			{label: 'A/P Phone', value: 'ap_phone'}
		];

		let customerHiddenForm = [
			{name: 'mod_quote_id', value: this.state.data.mod_quote_id},
			{name: 'project_id', value: this.state.data.project_id},
			{name: 'create_date', value: this.state.data.create_date},
			{name: 'tsi_contact', value: this.state.data.tsi_contact},
			{name: 'contact_name', value: this.state.data.contact_name},
			{name: 'contact_phone', value: this.state.data.contact_phone},
			{name: 'product_1', value: this.state.data.product_1},
			{name: 'product_1_price', value: this.state.data.product_1_price},
			{name: 'product_1_desc', value: this.state.data.product_1_desc},
			{name: 'tech_needed', value: this.state.data.tech_needed},
			{name: 'tech_rate', value: this.state.data.tech_rate},
			{name: 'product_2', value: this.state.data.product_2},
			{name: 'product_2_price', value: this.state.data.product_2_price},
			{name: 'product_2_desc', value: this.state.data.product_2_desc},
			{name: 'product_3', value: this.state.data.product_3},
			{name: 'product_3_price', value: this.state.data.product_3_price},
			{name: 'product_3_desc', value: this.state.data.product_3_desc},
			{name: 'mod_record_id', value: this.state.data.mod_record_id},
			{name: 'ship_company', value: this.state.data.ship_company},
			{name: 'ship_contact', value: this.state.data.ship_contact},
			{name: 'ship_city', value: this.state.data.ship_city},
			{name: 'ship_state_prov', value: this.state.data.ship_state_prov},
			{name: 'ship_zip_postal', value: this.state.data.ship_zip_postal},
			{name: 'invoice_equal_ship', value: this.state.data.invoice_equal_ship},
			{name: 'ID', value: this.state.data.ID},
			{name: 'CreatedAt', value: this.state.data.CreatedAt},
			{name: 'UpdatedAt', value: this.state.data.UpdatedAt},
			{name: 'DeletedAt', value: ''}
		];

		customerItemData = [
			this.state.data.product_1,
			this.state.data.product_1_desc,
			this.state.data.product_1_price,
			this.state.data.product_2,
			this.state.data.product_2_price,
			this.state.data.product_2_desc,
			this.state.data.product_3,
			this.state.data.product_3_desc,
			this.state.data.product_3_price
		];

		return (
			<div className="App">
				<Header
					constructForm={this.buildForm}
					headerData={this.headerData(customerHeaderData)}
				/>
				<form id="formContainer" name="formContainer">
					<div className="form">
						<div className="formHeader">BILL TO</div>
						<div className="formHeader"></div>
						<div className="formColumn">
							{this.buildForm(customerBillData1)}
						</div>
						<div className="formColumn">
							{this.buildForm(customerBillData2)}
							<div className="specialMessage">
								<i className="fas fa-exclamation-circle"></i>Please update or correct BILLING information.
							</div>
						</div>
					</div>

					<div className="itemsList">
						{this.renderListItems(customerItemData)}
					</div>
					<div className="footer">
						<div className="statusBoxContainer">
							<div className="statusBox" id="msg1">
								<div><i className="fas fa-info-circle"></i>Enter your name and click 'I Accept' as your digital signature</div>
							</div>
							<div className="statusBox" id="msg2">
								<div className="approvedMessage"><i className="far fa-thumbs-up"></i>Approved</div>
								<div>THANK YOU. We appreciate your business!</div>
							</div>
						</div>
						<div className="row">
							<div className="inputGroup fg-2">
								<label className="label">PO Number (if applicable)</label>
								<input
									type="text"
									className="input"
									defaultValue={po_number}
									name="po_number"
								/>
							</div>
							<div className="row fg-3">
								<div className="inputGroup fg-2">
									<label className="label">Enter your name<span className="errorMsg hidden">You must enter a valid name</span></label>
									<input
										type="text"
										className="input"
										defaultValue={authorization_name}
										onChange={this.handleAuthValue}
										name="authorization_name"
										id="authName"
									/>
								</div>
								<button className="acceptBtn" disabled={!isEnabled} onClick={(e) => {
									this.submitSend(e);
								}}><i className="fas fa-pen-alt"></i>I Accept</button>
							</div>
						</div>
					</div>
					<div>{this.buildHiddenForm(customerHiddenForm)}</div>
				</form>
			</div>
		);
	}
}

export default App;
